import React from 'react';
import styles from './styles/PremiumPromise.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import LinkButton from '../LinkButton';
import { Carousel } from 'react-responsive-carousel';
import PromisePart from './PromisePart';
import { GET_STARTED_PREMIUM_ROUTE } from '../../constants/routes';

const PremiumPromise = () => {
  const images = useStaticQuery(graphql`
    {
      part1: file(relativePath: { eq: "premium/part1-promise.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      part2: file(relativePath: { eq: "premium/part2-promise.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      part3: file(relativePath: { eq: "premium/part3-promise.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const PartOne = () => {
    return (
      <PromisePart
        imageFluid={images.part1.childImageSharp.fluid}
        partContainer={styles.partContainer}
      >
        <div className={styles.mainText}>
          A <span className={styles.blue}>premium</span> insurance experience
          shaped for you.
        </div>
      </PromisePart>
    );
  };
  const PartTwo = () => {
    return (
      <PromisePart
        imageFluid={images.part2.childImageSharp.fluid}
        partContainer={styles.partContainer}
      >
        <div className={styles.mainText}>
          Helping Kiwis <span className={styles.blue}>gain confidence</span>{' '}
          with their insurance.
        </div>
      </PromisePart>
    );
  };
  const PartThree = () => {
    return (
      <PromisePart
        imageFluid={images.part3.childImageSharp.fluid}
        partContainer={styles.partContainer}
      >
        <div className={styles.mainText}>
          Experience a <span className={styles.blue}>personalised</span> online
          service that is easy to use.
        </div>
      </PromisePart>
    );
  };

  const indicatorStyles = {
    background: '#c7c7c7',
    width: 10,
    height: 10,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: 999,
  };

  return (
    <>
      <div className={styles.premiumPromiseContainer}>
        <PartOne />
        <PartTwo />
        <PartThree />
      </div>
      <div className={styles.premiumPromiseMobileView}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ ...indicatorStyles, background: '#4187f5' }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                />
              );
            }
            return (
              <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          <PartOne />
          <PartTwo />
          <PartThree />
        </Carousel>
        <LinkButton
          href={GET_STARTED_PREMIUM_ROUTE}
          background="#4187F5"
          className={styles.mobileBlueBtn}
        >
          <div className={styles.buttonText}>Sign up today</div>
        </LinkButton>
      </div>
    </>
  );
};

export default PremiumPromise;
