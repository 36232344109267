import React, { useState } from 'react';
import cx from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styles from './styles/HowItWorks.module.scss';
import Intersection from '../Intersection';
import Arrow from '../../images/premium/RightArrow.svg';

const HowItWorks = () => {
  const [active, setActive] = useState(0);

  const images = useStaticQuery(graphql`
    {
      stepOne: file(relativePath: { eq: "premium/stepOne.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepTwo: file(relativePath: { eq: "premium/stepTwo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepThree: file(relativePath: { eq: "premium/stepThree.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepOneCut: file(relativePath: { eq: "premium/stepOneCut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepTwoCut: file(relativePath: { eq: "premium/stepTwoCut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepThreeCut: file(relativePath: { eq: "premium/stepThreeCut.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleIntersect = (num) => {
    if (active < num) {
      setActive(num);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sides}>
        <div className={styles.leftSide}>
          <div className={styles.stickyWrap}>
            <div className={styles.header}>
              <div className={styles.label}>Quashed Premium</div>
              <div className={styles.heading}>
                <span className={styles.blue}>How</span> it works.
                <Arrow className={styles.arrow} />
              </div>
            </div>
            <div
              className={cx(styles.stepContainer, active >= 0 && styles.active)}
            >
              <div className={styles.steps}>
                1. Select policies
                <div className={styles.stepContent}>
                  Let us know what types of insurance we can help you with.
                </div>
                <Image
                  className={styles.mobileImg}
                  fluid={images.stepOneCut.childImageSharp.fluid}
                />
              </div>
            </div>
            <div
              className={cx(styles.stepContainer, active >= 1 && styles.active)}
            >
              <div className={styles.steps}>
                2. Provide your details
                <div className={styles.stepContent}>
                  Fill out quick and easy online forms.
                </div>
                <Image
                  className={styles.mobileImg}
                  fluid={images.stepTwoCut.childImageSharp.fluid}
                />
              </div>
            </div>
            <div
              className={cx(styles.stepContainer, active >= 2 && styles.active)}
            >
              <div className={styles.steps}>
                3. Get your insurance sorted
                <div className={styles.stepContent}>
                  Our adviser will help you find the best insurance policy.
                </div>
                <Image
                  className={styles.mobileImg}
                  fluid={images.stepThreeCut.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={cx(styles.imageContainer, styles.active)}>
            <Image
              className={styles.image1}
              fluid={images.stepOne.childImageSharp.fluid}
            />
          </div>
          <Intersection
            className={cx(styles.imageContainer, active === 1 && styles.active)}
            onIntersect={() => handleIntersect(1)}
          >
            <Image
              className={styles.image2}
              fluid={images.stepTwo.childImageSharp.fluid}
            />
          </Intersection>
          <Intersection
            className={cx(styles.imageContainer, active === 2 && styles.active)}
            onIntersect={() => handleIntersect(2)}
          >
            <Image
              className={styles.image3}
              fluid={images.stepThree.childImageSharp.fluid}
            />
          </Intersection>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
