import React from 'react';
import styles from './styles/PromisePart.module.scss';
import cx from 'classnames';
import Image from 'gatsby-image';
import LinkButton from '../LinkButton';
import { GET_STARTED_PREMIUM_ROUTE } from '../../constants/routes';

const PromisePart = ({ imageFluid, partContainer, children }) => {
  return (
    <div className={cx(partContainer)}>
      <div className={styles.imageCard}>
        <Image fluid={imageFluid} />
      </div>
      <div className={styles.textCard}>
        <div className={styles.label}>Our Promise</div>
        {children}
        <LinkButton
          href={GET_STARTED_PREMIUM_ROUTE}
          background="#4187F5"
          className={styles.blueBtn}
        >
          Sign up today
        </LinkButton>
      </div>
    </div>
  );
};

export default PromisePart;
