import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import styles from './styles/PremiumHero.module.scss';
import DownArrow from '../../images/premium/DownArrow.svg';
import Sponsors from '../Sponsors';
import LinkButton from '../LinkButton';
import { GET_STARTED_PREMIUM_ROUTE } from '../../constants/routes';

const PremiumHero = () => {
  const image = useStaticQuery(
    graphql`
      query {
        heroBackgroundImage: file(relativePath: { eq: "premium/splash.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <BackgroundImage
      Tag="section"
      className={styles.heroSplashContainer}
      fluid={image.heroBackgroundImage.childImageSharp.fluid}
    >
      <div className={styles.headerCard}>
        <div className={styles.headerCardContent}>
          <div>
            <DownArrow className={styles.arrow} />
          </div>
          <div>
            <div className={styles.rightSideCard}>
              <div className={styles.subLabel}>Quashed Premium</div>
              <div>
                <span className={styles.subtitle}>
                  Forget everything you know about brokers and experience a{' '}
                  <span className={styles.subtitleHighlight}>premium </span>
                  way to do your{' '}
                  <span className={styles.subtitleHighlight}>
                    insurance{' '}
                  </span>{' '}
                  today.
                </span>
              </div>
              <LinkButton
                href={GET_STARTED_PREMIUM_ROUTE}
                background="#4187F5"
                className={styles.blueBtn}
              >
                Get Started
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Sponsors className={styles.sponsor} linkClassName={styles.sponsorLink}>
        <span className={styles.featuredLabel}>FEATURED IN</span>
      </Sponsors>

      <div className={styles.bottomFade}></div>
    </BackgroundImage>
  );
};
export default PremiumHero;
