import React from 'react';
import styles from './styles/PremiumInsuranceProviders.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const ImageLink = ({ href, fluid }) => (
  <a className={styles.brandsLink} href={href} target="_blank" rel="noreferrer">
    <Image className={styles.brand} fluid={fluid} />
  </a>
);

const PremiumInsuranceProviders = () => {
  const images = useStaticQuery(graphql`
    {
      southernCross: file(
        relativePath: { eq: "premium/providers/SouthernCross.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aia: file(relativePath: { eq: "premium/providers/Aia.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cigna: file(relativePath: { eq: "premium/providers/Cigna.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vero: file(relativePath: { eq: "premium/providers/Vero.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ando: file(relativePath: { eq: "premium/providers/Ando.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nzi: file(relativePath: { eq: "premium/providers/Nzi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      star: file(relativePath: { eq: "premium/providers/Star.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chubb: file(relativePath: { eq: "premium/providers/Chubb.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partnersLife: file(
        relativePath: { eq: "premium/providers/PartnersLife.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={styles.brands}>
      <ImageLink
        href={'https://www.southerncross.co.nz/'}
        fluid={images.southernCross.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.aia.co.nz/en/index.html'}
        fluid={images.aia.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.cigna.com/'}
        fluid={images.cigna.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.vero.co.nz/'}
        fluid={images.vero.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.ando.co.nz/'}
        fluid={images.ando.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.nzi.co.nz/'}
        fluid={images.nzi.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://starinsure.co.nz/'}
        fluid={images.star.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.chubb.com/nz-en/'}
        fluid={images.chubb.childImageSharp.fluid}
      />
      <ImageLink
        href={'https://www.partnerslife.co.nz/'}
        fluid={images.partnersLife.childImageSharp.fluid}
      />
    </div>
  );
};

export default PremiumInsuranceProviders;
