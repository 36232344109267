import React from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styles from './styles/Sponsors.module.scss';
import cx from 'classnames';

const imgStyle = { objectFit: 'contain' };

const ImageLink = ({ href, className, ...props }) => (
  <a
    className={cx(className, styles.sponsorLink)}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    <Img imgStyle={imgStyle} className={styles.sponsorImg} {...props} />
  </a>
);

const Sponsors = ({ className, titleClassName, children, linkClassName }) => {
  const images = useStaticQuery(
    graphql`
      {
        backer1: file(relativePath: { eq: "brands/Icehouse.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 270) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        feature1: file(relativePath: { eq: "brands/NZ_herald.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature2: file(relativePath: { eq: "brands/Business_desk.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 175) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature3: file(relativePath: { eq: "brands/newshub.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 95) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature4: file(relativePath: { eq: "brands/Insurance_business.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 110) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature5: file(relativePath: { eq: "brands/RNZ.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 120) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature6: file(relativePath: { eq: "brands/stuff.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        feature7: file(relativePath: { eq: "brands/fairGo.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `,
  );

  return (
    <div
      className={classnames(
        'container text-center',
        styles.sponsors,
        className,
      )}
    >
      <div className={classnames(styles.title, titleClassName)}>
        Featured in
      </div>
      <div className={styles.sponsor}>
        <ImageLink
          // href="https://www.nzherald.co.nz/business/kiwi-insurance-start-up-attracts-backing-from-icehouse-ventures/ZHENRMKRW2R72R563N6JW6BTK4/"
          fixed={images.feature1.childImageSharp.fixed}
          alt="Feature #1"
          className={linkClassName}
        />
        <ImageLink
          // href="https://businessdesk.co.nz/article/technology/600000-in-funding-for-the-sharesies-of-insurance"
          fixed={images.feature2.childImageSharp.fixed}
          alt="Feature #2"
          className={linkClassName}
        />
        <ImageLink
          // href="https://www.newshub.co.nz/home/money/2022/01/ten-insurance-mistakes-that-cost-you-money.html"
          fixed={images.feature3.childImageSharp.fixed}
          alt="Feature #3"
          className={linkClassName}
        />
        <ImageLink
          // href="https://www.insurancebusinessmag.com/nz/news/technology/why-insurance-businesses-should-do-regular-customer-testing-302282.aspx"
          fixed={images.feature4.childImageSharp.fixed}
          alt="Feature #4"
          className={linkClassName}
        />
        <ImageLink
          // href="https://www.rnz.co.nz/news/business/437686/online-insurance-platform-aims-to-simplify-policy-information"
          fixed={images.feature5.childImageSharp.fixed}
          alt="Feature #5"
          className={linkClassName}
        />
        <ImageLink
          // href="https://www.stuff.co.nz/business/money/129747746/average-twocar-household-could-save-424ayear-by-shopping-around-for-vehicle-insurance"
          fixed={images.feature6.childImageSharp.fixed}
          alt="Feature #6"
          className={linkClassName}
        />
        <ImageLink
          // href="https://www.1news.co.nz/2022/10/04/fair-gos-tips-for-saving-money-on-insurance-without-losing-cover/"
          fixed={images.feature7.childImageSharp.fixed}
          alt="Feature #7"
          className={linkClassName}
        />
      </div>
    </div>
  );
};

export default Sponsors;
