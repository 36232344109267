import React from 'react';
import useContentfulBlogs from '../../hooks/useContentfulBlogs';
import useContentfulContentPillar from '../../hooks/useContentfulContentPillar';
import styles from './styles/PremiumBlog.module.scss';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import ContentfulLinks from '../ContentfulLinks';
import ClockIcon from '../../images/premium/clock.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const blogTitles = [
  "If you're a landlord, here's why you should use an insurance adviser",
  'Three good reasons why an adviser is needed for personal insurance',
];

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ContentfulLinks,
  },
};

const PremiumBlogs = () => {
  const blogs = useContentfulBlogs();
  const contentPillar = useContentfulContentPillar('insurance-adviser');
  const {
    readTime: ctReadTime,
    title: ctTitle,
    titleCover: ctTitleCover,
    titleDescription: ctTitleDescription,
  } = contentPillar;
  const filteredBlogs = blogs.filter(
    ({ node }) => node.title === blogTitles[0] || node.title === blogTitles[1],
  );

  const Item = ({
    title,
    subtitle,
    blogUrl,
    photo,
    alt,
    description,
    readTime,
  }) => {
    const fullTitle = subtitle ? `${title} ${subtitle}` : title;

    const desc =
      description && description.content
        ? {
            ...description,
            content: description.content.filter(
              (c) => c.nodeType === 'paragraph',
            ),
          }
        : description;

    return (
      <div
        className={styles.blogCard}
        to={blogUrl}
        title={fullTitle}
        rel="bookmark"
      >
        <img src={photo} alt={alt} />
        <div className={styles.itemContent}>
          <div className={styles.itemTitle} title={fullTitle}>
            {fullTitle}
          </div>

          {description && (
            <>
              <div className={styles.description}>
                {documentToReactComponents(desc, options)[0]}
              </div>
              {!!readTime && (
                <div className={styles.readTimeContainer}>
                  <ClockIcon />
                  <span
                    className={styles.readTime}
                  >{` ${readTime} min read`}</span>
                </div>
              )}
              <div className={styles.readMore}>
                <Link to={blogUrl}>Read more</Link>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const indicatorStyles = {
    background: '#c7c7c7',
    width: 10,
    height: 10,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: 999,
  };
  return (
    <>
      <div className={styles.blogContainer}>
        {filteredBlogs.map(({ node }) => (
          <Item
            key={node.title}
            title={node.title}
            alt={node.title}
            description={node.richText.json}
            photo={node.photo.fluid.src}
            readTime={node.readTime}
            blogUrl={`/blog/${node.slug}`}
            subtitle={node.subtitle}
          />
        ))}
        <Item
          title={ctTitle}
          alt={ctTitle}
          description={ctTitleDescription.json}
          photo={ctTitleCover.fluid.src}
          readTime={ctReadTime}
          blogUrl={'/guides/insurance-adviser'}
        />
      </div>
      <Carousel
        className={styles.mobileBlogContainer}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...indicatorStyles, background: '#4187f5' }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={indicatorStyles}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        {filteredBlogs.map(({ node }) => (
          <Item
            key={node.title}
            title={node.title}
            alt={node.title}
            description={node.richText.json}
            photo={node.photo.fluid.src}
            readTime={node.readTime}
            blogUrl={`/blog/${node.slug}`}
            subtitle={node.subtitle}
          />
        ))}
        <Item
          title={ctTitle}
          alt={ctTitle}
          description={ctTitleDescription.json}
          photo={ctTitleCover.fluid.src}
          readTime={ctReadTime}
          blogUrl={'/guides/insurance-adviser'}
        />
      </Carousel>
    </>
  );
};

export default PremiumBlogs;
