import React from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './styles/PremiumInsuranceTypes.module.scss';

const PremiumInsuranceTypes = () => {
  const images = useStaticQuery(graphql`
    {
      homeIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-house.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contentsIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-contents.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-vehicle.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landlordIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-landlord.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifeIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-life.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      commercialIcon: file(
        relativePath: { eq: "premium/insurance-types/premium-business.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={styles.insuranceTypeContainer}>
      <div className={styles.pairContainer}>
        <Image fluid={images.homeIcon.childImageSharp.fluid} />
        <div className={styles.label2}>House & holiday homes</div>
      </div>
      <div className={styles.pairContainer}>
        <Image fluid={images.contentsIcon.childImageSharp.fluid} />
        <div className={styles.label2}>Valuable contents</div>
      </div>
      <div className={styles.pairContainer}>
        <Image fluid={images.carIcon.childImageSharp.fluid} />
        <div className={styles.label2}>Performance vehicles</div>
      </div>
      <div className={styles.pairContainer}>
        <Image fluid={images.landlordIcon.childImageSharp.fluid} />
        <div className={styles.label2}>Landlord insurance</div>
      </div>
      <div className={styles.pairContainer}>
        <Image fluid={images.lifeIcon.childImageSharp.fluid} />
        <div className={styles.label2}>Life & health insurance</div>
      </div>
      <div className={styles.CommercialContainer}>
        <Image fluid={images.commercialIcon.childImageSharp.fluid} />
        <div className={styles.label2}>Commercial insurance</div>
      </div>
    </div>
  );
};

export default PremiumInsuranceTypes;
