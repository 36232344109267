import React from 'react';
import styles from './styles/PremiumPreFooter.module.scss';
import LinkButton from '../LinkButton';
import { GET_STARTED_PREMIUM_ROUTE } from '../../constants/routes';

const PremiumPreFooterNote = () => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>Quashed Premium</div>
      <div className={styles.largeText}>
        <div className={styles.mainText}>
          Industry experts who can <span className={styles.blue}>simplify</span>
        </div>
        <div className={styles.mainText}>
          <span className={styles.blue}>insurance</span> and deliver you a{' '}
          <div className={styles.mainText}>
            <span className={styles.blue}>premium experience</span>.
          </div>
        </div>
      </div>

      <div className={styles.mobileText}>
        <div className={styles.mainText}>
          Industry experts who can{' '}
          <span className={styles.blue}>simplify insurance </span>
          and deliver you a{' '}
          <span className={styles.blue}>premium experience</span>.
        </div>
      </div>

      <LinkButton
        href={GET_STARTED_PREMIUM_ROUTE}
        background="#4187F5"
        className={styles.blueBtn}
      >
        Get a quote today
      </LinkButton>
    </div>
  );
};

export default PremiumPreFooterNote;
