import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import PremiumBlogs from '../components/premium/PremiumBlog';
import PremiumInsuranceProviders from '../components/premium/PremiumInsuranceProviders';
import PremiumInsuranceTypes from '../components/premium/PremiumInsuranceTypes';
import PremiumPromise from '../components/premium/PremiumPromise';
import HowItWorks from '../components/premium/HowItWorks';
import Layout from '../components/Layout';
import DownArrow from '../images/premium/DownArrow.svg';
import styles from './styles/premium.module.scss';
import PremiumHero from '../components/premium/PremiumHero';
import LinkButton from '../components/LinkButton';
import PremiumPreFooterNote from '../components/premium/PremiumPreFooter';
import { Scroll } from '../components/Animation';
import {
  GET_STARTED_PREMIUM_ROUTE,
  PREMIUM_FLOW_ROUTE,
} from '../constants/routes';
import SEO from '../components/seo';

const Premium = () => {
  const images = useStaticQuery(graphql`
    {
      friendlyAdviser: file(
        relativePath: { eq: "premium/friendlyAdviser.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      happyCouple: file(relativePath: { eq: "premium/oldCouple.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const InsuranceInfo = () => {
    return (
      <Scroll className={styles.sectionContainer}>
        <div className={styles.containerWithArrow}>
          <DownArrow className={styles.arrow} />
          <div>
            <div className={styles.subLabel}>Quashed Premium</div>
            <div className={styles.subtitleHighlight}>Types of insurance</div>
            <div className={styles.subtitle}> we can help you with.</div>
          </div>
        </div>
        <PremiumInsuranceTypes />
        <div className={styles.sectionWithoutArrow}>
          <div>
            <span className={styles.subtitle}>Some </span>
            <span className={styles.subtitleHighlight}>
              insurance providers
            </span>
          </div>
          <div className={styles.subtitle}>available through our adviser.</div>
        </div>
        <PremiumInsuranceProviders />
      </Scroll>
    );
  };
  const WeTakeCareOfYou = () => {
    return (
      <div className={styles.overlappingContainer}>
        <div className={styles.test}>
          <Image
            className={styles.friendlyAdviserImg}
            fluid={images.friendlyAdviser.childImageSharp.fluid}
          />
          <Image
            className={styles.happyCoupleImg}
            fluid={images.happyCouple.childImageSharp.fluid}
          />
        </div>

        <div className={styles.overlapTextCard}>
          <div>
            <span className={styles.infoHeaderText}>
              Let us
              <span className={styles.infoHeaderTextHighLight}>
                {' '}
                take care{' '}
              </span>
              of you.
            </span>
          </div>
          <div className={styles.cardInfoText}>
            We offer a premium service from reviewing your existing covers, to
            comparing and purchasing new policies and giving you your very own
            insurance dashboard.
          </div>
          <LinkButton
            href={GET_STARTED_PREMIUM_ROUTE}
            background="#4187F5"
            className={styles.blueBtn}
          >
            <div className={styles.buttonText}>Try Quashed Premium</div>
          </LinkButton>
        </div>
      </div>
    );
  };
  const Blog = () => {
    return (
      <div className={styles.blogSection}>
        <div className={styles.blogTitle}>
          <span className={styles.blogSubTitle}>
            Helpful
            <span className={styles.blogTitleHighlight}> guides</span>,
            <span className={styles.blogTitleHighlight}> tips </span>
            and
            <span className={styles.blogTitleHighlight}> tools.</span>
          </span>
        </div>
        <PremiumBlogs />
      </div>
    );
  };
  const meta = [
    { name: 'geoRegion', content: 'NZ-AUK' },
    { name: 'geoPlacename', content: 'Auckland' },
    { name: 'geoPosition', content: '-36.8114642;174.6210002' },
    { name: 'geoICBM', content: '-36.8114642;174.6210002' },
  ];
  return (
    <Layout
      mini={true}
      className={styles.layoutContainer}
      appUrl={PREMIUM_FLOW_ROUTE}
      signUpUrl={GET_STARTED_PREMIUM_ROUTE}
      footerClassName={styles.footer}
    >
      <SEO title="Premium" meta={meta} />
      <PremiumHero />
      <div className={styles.container}>
        <div></div>
        <div>
          <InsuranceInfo />
          <PremiumPromise />
          <HowItWorks />
          <WeTakeCareOfYou />
          <Scroll>
            <Blog />
          </Scroll>
        </div>
      </div>
      <Scroll>
        <PremiumPreFooterNote />
      </Scroll>
    </Layout>
  );
};

export default Premium;
