import React, { useEffect, useRef } from 'react';

const Intersection = ({ children, className, onIntersect }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          onIntersect();
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef.current]);

  return (
    <div ref={containerRef} className={className}>
      {children}
    </div>
  );
};

export default Intersection;
